<template>
  <div class="content_container">

   
    <el-breadcrumb
      separator-class="el-icon-arrow-right "
      class="titleNav"
    >

      <el-breadcrumb-item>
        <span @click="$router.push('/member-consult')">我的议价 </span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>议价详情</el-breadcrumb-item>
    </el-breadcrumb>
 
    <el-form
      ref="ruleForm"
      label-width="140px"
      class="ruleForm"
    >

      <el-form-item label="议价产品名称">
        {{ruleForm.goodname}}
      </el-form-item>
      <el-form-item label="产品图">
        <img
          :src="$config.baseUrl+ruleForm.goodlogo"
          style="width:200px"
        >

      </el-form-item>
      <el-form-item label="议价人">
        {{ruleForm.name}}
      </el-form-item>
      <el-form-item label="电话">
        {{ruleForm.phone}}
      </el-form-item>
      <el-form-item label="需求数量">
        {{ruleForm.num}}
      </el-form-item>
       <el-form-item label="提交时间">
        {{ new Date(ruleForm.add_date).format('yyyy-MM-dd') }}
      </el-form-item>
      
      <el-form-item label="议价内容">
         <span
        v-if="ruleForm.content"
       
       >  {{ruleForm.content}}</span> <span v-else>无</span>
      
      </el-form-item>
 <el-form-item label=" ">
        <el-button
          style="margin-left:300px;width:100px; margin-top:30px"
          type="primary"
          @click="$router.go(-1)"
        >返回</el-button>
      </el-form-item>
    </el-form>
      
  </div>

</template>

<script type="text/javascript">
// import this.$config from '../../../this.$config/this.$config'
export default {
  data() {
    return {
      ruleForm: {}
    };
  },
  filters: {},
  methods: {
    getSeek() {
      this.$get("seller/goodmessage/" + this.$route.query.id).then(res => {
        this.ruleForm = res.result;
      });
    },
    returnback() {
      this.$router.push("/member-consult");
    }
  },
  components: {},
  created() {
    this.getSeek();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";
// ::v-deep .el-form-item__label{ margin-right: 20px;
//   background: #eee;
//   display: block;height: 100%;
// }
::v-deep .el-form-item {
  margin-bottom: 2px;
}

.ruleForm {
  background: #fff;
  padding: 10px;
}
.titleNav {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background: #fff;
}
</style>
